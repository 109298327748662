import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ExpandablePanel } from "@entur/expand";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "search-guide"
    }}>{`Search Guide`}</h1>
    <p>{`This document is a guide for using Offers API v2 when searching for offers.`}</p>
    <p>{`In Offers v2 OfferSummaries are returned.
The OfferSummary contains stripped and concise information regarding an offer.
The products that give a user a right to travel are found under "preassignedProducts".
Each PreassignedProduct has an attribute "properties" shared with optionalProducts that contains most of the information needed for a given product.`}</p>
    <p>{`To view the complete offer structure issue a `}<inlineCode parentName="p">{`GET`}</inlineCode>{` request to `}<inlineCode parentName="p">{`https://api.entur.io/offers/v2/{offer-id}`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#search-by-trip-pattern"
        }}>{`Search by trip pattern`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/#examplerequests-to-searchtrip-pattern"
            }}>{`ExampleRequests`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#exampleresponses-from-searchtrip-pattern"
            }}>{`ExampleResponses`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#search-by-stop-places"
        }}>{`Search by stop places`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#examplerequest-to-searchstop-places"
            }}>{`ExampleRequests`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#exampleresponse-from-searchstop-places"
            }}>{`ExampleResponses`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#search-by-authority"
        }}>{`Search by authority`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#examplerequest-to-searchauthority"
            }}>{`ExampleRequests`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#exampleresponse-from-searchauthority"
            }}>{`ExampleResponses`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#search-by-zones"
        }}>{`Search by zones`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#examplerequests-to-searchzones"
            }}>{`ExampleRequests`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#exampleresponses-from-searchzones"
            }}>{`ExampleResponses`}</a></li>
        </ul>
      </li>
    </ul>
    <br />
    <h1 {...{
      "id": "search-by-trip-pattern"
    }}><a parentName="h1" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/trip-pattern/postOffersV2SearchTripPattern"
      }}>{`Search by trip pattern`}</a></h1>
    <p>{`Use the search/trip-pattern endpoint to request offers for a specific departure between two stop places.
The endpoint requires that at least one Traveller, and that a TripPatternRequest is specified.`}</p>
    <h2 {...{
      "id": "examplerequests-to-searchtrip-pattern"
    }}>{`ExampleRequests to search/trip-pattern`}</h2>
    <ExpandablePanel title="Basic Request Trondheim-Bodø" mdxType="ExpandablePanel">
    <pre>
    {`{
  "travellers": [
    {
      "userType": "ADULT",
      "baggageTypes": [
        "PUSH_CHAIR",
        "ANIMAL",
        "SMALL_ANIMAL",
        "BICYCLE"
      ]
    }
  ],
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2022-03-16",
        "fromStopPlaceId": "NSR:StopPlace:659",
        "toStopPlaceId": "NSR:StopPlace:507",
        "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
      }
    ]
  },
  "recommendationConfig": {
    "categorySpec": {
      "typesOfRecommendation": [
        "FLEXIBLE",
        "SEMI_FLEXIBLE",
        "NON_FLEXIBLE",
        "CHEAPEST"
      ],
      "fareClasses": [
        "PREMIUM_CLASS",
        "STANDARD_CLASS",
        "ECONOMY_CLASS",
        "ANY"
      ],
      "facilitySet": [
        "SLEEPER",
        "ANY_FACILITY_SET",
        "COUCHETTE"
      ]
    },
    "ruleSpec": {
      "journeyOrganizeAlgorithm": "COMBINATIONS_FROM_OFFERS",
      "priceComparisonAlgorithm": "TOTAL_PRICE",
      "onlyIncludeRecommendedOffers": true,
      "onlyIncludeRecommendationsWithOffersToBuy": true,
      "mixinOffersWithHigherFlexibility": true
    }
  }
}`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Oslo-Gardermoen with 30DayPass valid for Zones RUT:TariffZone:1, RUT:TariffZone:2Ø, RUT:TariffZone:3Ø" mdxType="ExpandablePanel">
    <pre>
    {`{
  "travellers": [
    {
        "id": "UNIQUE_IDENTIFIER",
      "userType": "ADULT",
      "age": 40,
      "productIds": ["RUT:PreassignedFareProduct:Ruter30Days"],
      "baggageTypes": [
        "PUSH_CHAIR",
        "ANIMAL",
        "SMALL_ANIMAL",
        "BICYCLE"
      ]
    }
  ],
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2022-02-24",
        "fromStopPlaceId": "NSR:StopPlace:337",
        "toStopPlaceId": "NSR:StopPlace:269",
        "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
      }
    ]
  },
  "existingTicket": {
      "id": "RUT:PreassignedFareProduct:Ruter30Days",
      "zones": [
          "RUT:TariffZone:1",
          "RUT:TariffZone:2Ø",
          "RUT:TariffZone:3Ø"
      ]
  },
  "recommendationConfig": {
    "categorySpec": {
      "typesOfRecommendation": [
        "FLEXIBLE",
        "SEMI_FLEXIBLE",
        "NON_FLEXIBLE",
        "CHEAPEST"
      ],
      "fareClasses": [
        "PREMIUM_CLASS",
        "STANDARD_CLASS",
        "ECONOMY_CLASS",
        "ANY"
      ],
      "facilitySet": [
        "SLEEPER",
        "ANY_FACILITY_SET",
        "COUCHETTE"
      ]
    },
    "ruleSpec": {
      "journeyOrganizeAlgorithm": "COMBINATIONS_FROM_OFFERS",
      "priceComparisonAlgorithm": "TOTAL_PRICE",
      "onlyIncludeRecommendedOffers": false,
      "onlyIncludeRecommendationsWithOffersToBuy": false,
      "mixinOffersWithHigherFlexibility": true
    }
  }
}`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Oslo-Bergen 4 travellers in 2 compartments" mdxType="ExpandablePanel">
    <pre>
    {`{
  "travellers": [
    {
        "id": "A",
      "userType": "ADULT",
      "age": 18
    },
    {
        "id": "B",
      "userType": "ADULT",
      "age": 18
    },
    {
        "id": "C",
      "userType": "ADULT",
      "age": 18
    },
    {
        "id": "D",
      "userType": "ADULT",
      "age": 18
    }
  ],
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2022-02-24",
        "fromStopPlaceId": "NSR:StopPlace:337",
        "toStopPlaceId": "NSR:StopPlace:548",
        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
      }
    ]
  },
  "recommendationConfig": {
    "categorySpec": {
      "typesOfRecommendation": [
        "FLEXIBLE",
        "SEMI_FLEXIBLE",
        "NON_FLEXIBLE",
        "CHEAPEST"
      ],
      "fareClasses": [
        "PREMIUM_CLASS",
        "STANDARD_CLASS",
        "ECONOMY_CLASS",
        "ANY"
      ],
      "facilitySet": [
        "SLEEPER",
        "ANY_FACILITY_SET",
        "COUCHETTE"
      ]
    },
    "ruleSpec": {
      "journeyOrganizeAlgorithm": "COMBINATIONS_FROM_OFFERS",
      "priceComparisonAlgorithm": "TOTAL_PRICE",
      "onlyIncludeRecommendedOffers": true,
      "onlyIncludeRecommendationsWithOffersToBuy": true,
      "mixinOffersWithHigherFlexibility": true
    }
  },
  "groupConfig": {
      "numberOfCompartments": 2
  }
}`}
    </pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "exampleresponses-from-searchtrip-pattern"
    }}>{`ExampleResponses from search/trip-pattern`}</h2>
    <ExpandablePanel title="Basic Request Trondheim-Bodø" mdxType="ExpandablePanel">
    <pre>
    {`{
  "tripPatternId": "9f527bd5-23db-4a05-bb4a-a75fc5451a27",
  "offers": [
    {
      "id": "ea10daf8-3d77-4a01-b9b4-81260ce8eefe",
      "name": "Standard non-refundable",
      "description": "",
      "price": {
        "amount": "639.00",
        "currency": "NOK"
      },
      "geographicalValidity": {
        "serviceJourneys": [
          {
            "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
            "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
          }
        ],
        "pointToPointValidity": {
          "fromPlace": "NSR:StopPlace:659",
          "toPlace": "NSR:StopPlace:507"
        }
      },
      "preassignedProducts": [
        {
          "id": "SJN:PreassignedFareProduct:Standard",
          "version": "SJN:Version:PFP-Standard-2",
          "name": "Standard non-refundable",
          "description": "- Cannot be changed or refunded\\n- You get a standard seat reserved\\n\\n[More info at SJ (in Norwegian)](https://www.sj.no/om-vare-billetter/vare-billettyper)",
          "properties": {
            "isExchangeable": false,
            "isRefundable": false,
            "accommodations": [
              {
                "serviceJourney": {
                  "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
                  "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
                },
                "accommodation": []
              }
            ],
            "reservingRequirements": [
              {
                "serviceJourney": {
                  "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
                  "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
                },
                "seatMapIsAvailable": true
              },
              {
                "serviceJourney": {
                  "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
                  "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
                },
                "reservingRequirement": "RESERVATIONS_COMPULSORY",
                "seatMapIsAvailable": false
              }
            ],
            "userProfileId": "SJN:UserProfile:Adult",
            "userProfileVersion": "SJN:Version:UPAdult-2020-1",
            "organisation": {
              "id": "SJN:Authority:SJN",
              "name": "SJ Nord"
            },
            "fareClasses": [
              "STANDARD_CLASS"
            ]
          },
          "geographicalValidity": {
            "serviceJourneys": [
              {
                "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
                "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
              }
            ],
            "pointToPointValidity": {
              "fromPlace": "NSR:StopPlace:659",
              "toPlace": "NSR:StopPlace:507"
            }
          }
        }
      ],
      "optionalProductsSelectableIds": [
        "keMdoG",
        "cB4AAY",
        "b0f6UI",
        "wC9t6m",
        "eIXy0B",
        "6tfKdb",
        "Utl3ls",
        "5BKCG8"
      ],
      "travellerMapping": [
        {
          "travellerIds": [
            "ad37276d-2a32-4d84-8c2f-08dc30f05cd6"
          ],
          "maxNumberOfTravellers": 1,
          "minNumberOfTravellers": 1,
          "userType": "ADULT"
        }
      ],
      "available": 68
    } ...
  ],
  "recommendations": [
    {
      "geographicalValidityCovered": {
        "serviceJourneys": [
          "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
        ],
        "pointToPointValidity": {
          "fromPlace": "NSR:StopPlace:659",
          "toPlace": "NSR:StopPlace:507"
        }
      },
      "typeOfRecommendation": "NON_FLEXIBLE",
      "offersToBuy": [
        {
          "id": "ea10daf8-3d77-4a01-b9b4-81260ce8eefe",
          "numberToBuy": 1,
          "withUpgradeProducts": [],
          "selectableProductIds": [],
          "possibleTravellerIds": [
            [
              "ad37276d-2a32-4d84-8c2f-08dc30f05cd6"
            ]
          ]
        }
      ]
    } ...
  ],
  "optionalProducts": [
    {
      "id": "SJN:SupplementProduct:BikeReservation",
      "version": "SJN:Version:SUP-BikeReservation-1",
      "selectableId": "keMdoG",
      "name": "Bicycle",
      "description": "There are reserved places for bicycles on the longer regional trains.",
      "price": {
        "amount": "215.00",
        "currency": "NOK"
      },
      "properties": {
        "accommodations": [
          {
            "serviceJourney": {
              "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
              "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
            },
            "accommodation": []
          }
        ],
        "baggageTypes": [
          "BICYCLE"
        ],
        "reservingRequirements": [
          {
            "serviceJourney": {
              "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
              "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
            },
            "reservingRequirement": "RESERVATIONS_COMPULSORY",
            "seatMapIsAvailable": false
          }
        ],
        "organisation": {
          "id": "SJN:Authority:SJN",
          "name": "SJ Nord"
        }
      },
      "geographicalValidity": {
        "serviceJourneys": [
          {
            "datedServiceJourneyId": "ENT:DatedServiceJourney:118975199",
            "serviceJourneyId": "SJN:ServiceJourney:SJN_475_2022_03_16_NORD"
          }
        ],
        "pointToPointValidity": {
          "fromPlace": "NSR:StopPlace:659",
          "toPlace": "NSR:StopPlace:507"
        }
      },
      "available": 5
    } ...
  ],
  "unavailableProducts": []
}`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Oslo-Gardermoen with 30DayPass valid for Zones RUT:TariffZone:1, RUT:TariffZone:2Ø, RUT:TariffZone:3Ø" mdxType="ExpandablePanel">
    <pre>
    {`{
    "tripPatternId": "26ed7b1c-65e0-45b3-bf97-d2ad8ebe3772",
    "offers": [
        {
            "id": "de659fde-a858-46ee-89f2-62deb6bdd57b",
            "name": "Enkeltbillett",
            "description": "",
            "price": {
                "amount": "39.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                        "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                    }
                ],
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:4N"
                    ]
                }
            },
            "preassignedProducts": [
                {
                    "id": "RUT:PreassignedFareProduct:RuterSingleTicket",
                    "version": "RUT:Version:V1",
                    "name": "Enkeltbillett",
                    "description": "",
                    "properties": {
                        "isExchangeable": false,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                    "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                                },
                                "accommodation": []
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                    "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                                },
                                "reservingRequirement": "NO_RESERVATIONS_POSSIBLE",
                                "seatMapIsAvailable": false
                            }
                        ],
                        "userProfileId": "RUT:UserProfile:Adult",
                        "userProfileVersion": "RUT:Version:V1",
                        "organisation": {
                            "id": "RUT:Authority:RUT",
                            "name": "Ruter"
                        },
                        "duration": "PT1H",
                        "fareClasses": [
                            "STANDARD_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                            }
                        ],
                        "zonalValidity": {
                            "zones": [
                                "RUT:TariffZone:4N"
                            ]
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "UNIQUE_IDENTIFIER"
                    ],
                    "maxNumberOfTravellers": 1,
                    "minNumberOfTravellers": 1,
                    "userType": "ADULT"
                }
            ]
        },
        {
            "id": "85b0ac2d-0a73-4b1c-bae6-8b06c29ad6d4",
            "name": "",
            "description": "",
            "price": {
                "amount": "25.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                        "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                    }
                ],
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:4N"
                    ]
                }
            },
            "preassignedProducts": [
                {
                    "id": "RUT:PreassignedFareProduct:SupplementaryTicket",
                    "version": "RUT:Version:V1",
                    "name": "Billett for ekstra soner",
                    "description": "",
                    "properties": {
                        "isExchangeable": false,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                    "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                                },
                                "accommodation": []
                            }
                        ],
                        "userProfileId": "RUT:UserProfile:Adult",
                        "userProfileVersion": "RUT:Version:V1",
                        "organisation": {
                            "id": "RUT:Authority:RUT",
                            "name": "Ruter"
                        },
                        "duration": "PT1H30M",
                        "fareClasses": [
                            "STANDARD_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81601112",
                                "serviceJourneyId": "NSB:ServiceJourney:1-2060-510-202202091151"
                            }
                        ],
                        "zonalValidity": {
                            "zones": [
                                "RUT:TariffZone:4N"
                            ]
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "UNIQUE_IDENTIFIER"
                    ],
                    "maxNumberOfTravellers": 1,
                    "minNumberOfTravellers": 1,
                    "userType": "ADULT"
                }
            ]
        }
    ],
    "recommendations": [
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "NSB:ServiceJourney:1-2060-510-202202091151"
                ],
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:4N"
                    ]
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "durationType": "SINGLE_TRIP",
            "offersToBuy": [
                {
                    "id": "85b0ac2d-0a73-4b1c-bae6-8b06c29ad6d4",
                    "numberToBuy": 1,
                    "withUpgradeProducts": [],
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "UNIQUE_IDENTIFIER"
                        ]
                    ]
                }
            ]
        } ...
    ]
}`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Oslo-Bergen 4 travellers in 2 compartments" mdxType="ExpandablePanel">
    <pre>
    {`{
    "tripPatternId": "f56da8ad-98d3-4ec8-b3d0-b82fed108158",
    "offers": [
        {
            "id": "68b49f9d-7334-4279-ab61-437d08eca977",
            "name": "Flex",
            "description": "",
            "price": {
                "amount": "279.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                    }
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "preassignedProducts": [
                {
                    "id": "VYT:PreassignedFareProduct:Flex",
                    "version": "VYT:Version:PFP-Flex-1",
                    "name": "Flex",
                    "description": "- Sete tildeles\\n- Kan endres\\n- Ikke refunderbar",
                    "properties": {
                        "isExchangeable": true,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "accommodation": [
                                    "SEATING"
                                ]
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "reservingRequirement": "RESERVATIONS_COMPULSORY",
                                "seatMapIsAvailable": true
                            }
                        ],
                        "userProfileId": "VYT:UserProfile:Adult",
                        "userProfileVersion": "VYT:Version:UP-Adult-1",
                        "organisation": {
                            "id": "VYT:Authority:VYT",
                            "name": "Vy Tog"
                        },
                        "fareClasses": [
                            "STANDARD_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                            }
                        ],
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:548"
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "A",
                        "B",
                        "C",
                        "D"
                    ],
                    "maxNumberOfTravellers": 1,
                    "minNumberOfTravellers": 1,
                    "userType": "ADULT"
                }
            ],
            "available": 2
        },
        {
            "id": "acef5e44-5a02-4136-ab6d-5aaa7d0233e2",
            "name": "Sove",
            "description": "",
            "price": {
                "amount": "999.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                    }
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "preassignedProducts": [
                {
                    "id": "VYT:PreassignedFareProduct:Sleep",
                    "version": "VYT:Version:PFP-Sleep-1",
                    "name": "Sove",
                    "description": "- Reis i egen sovekupé\\n- En kupé består av to oppredde senger på 75 cm bredde\\n- Kan endres\\n- Ikke refunderbar",
                    "properties": {
                        "isExchangeable": true,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "accommodation": [
                                    "SLEEPER"
                                ]
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "reservingRequirement": "RESERVATIONS_COMPULSORY",
                                "seatMapIsAvailable": false
                            }
                        ],
                        "organisation": {
                            "id": "VYT:Authority:VYT",
                            "name": "Vy Tog"
                        },
                        "group": {
                            "id": "VYT:GroupTicket:Sleep",
                            "version": "VYT:Version:GT-Sleep-1",
                            "description": "",
                            "userProfiles": []
                        },
                        "fareClasses": [
                            "PREMIUM_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                            }
                        ],
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:548"
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "B",
                        "D"
                    ],
                    "maxNumberOfTravellers": 2,
                    "minNumberOfTravellers": 2,
                    "userType": "ADULT"
                }
            ],
            "available": 5
        },
        {
            "id": "9e71c4ee-2173-47d9-882d-d1d6d38f736f",
            "name": "Sove",
            "description": "",
            "price": {
                "amount": "999.00",
                "currency": "NOK"
            },
            "geographicalValidity": {
                "serviceJourneys": [
                    {
                        "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                    }
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "preassignedProducts": [
                {
                    "id": "VYT:PreassignedFareProduct:Sleep",
                    "version": "VYT:Version:PFP-Sleep-1",
                    "name": "Sove",
                    "description": "- Reis i egen sovekupé\\n- En kupé består av to oppredde senger på 75 cm bredde\\n- Kan endres\\n- Ikke refunderbar",
                    "properties": {
                        "isExchangeable": true,
                        "isRefundable": false,
                        "accommodations": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "accommodation": [
                                    "SLEEPER"
                                ]
                            }
                        ],
                        "reservingRequirements": [
                            {
                                "serviceJourney": {
                                    "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                    "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                                },
                                "reservingRequirement": "RESERVATIONS_COMPULSORY",
                                "seatMapIsAvailable": false
                            }
                        ],
                        "organisation": {
                            "id": "VYT:Authority:VYT",
                            "name": "Vy Tog"
                        },
                        "group": {
                            "id": "VYT:GroupTicket:Sleep",
                            "version": "VYT:Version:GT-Sleep-1",
                            "description": "",
                            "userProfiles": []
                        },
                        "fareClasses": [
                            "PREMIUM_CLASS"
                        ]
                    },
                    "geographicalValidity": {
                        "serviceJourneys": [
                            {
                                "datedServiceJourneyId": "ENT:DatedServiceJourney:81349875",
                                "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2839-R"
                            }
                        ],
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:548"
                        }
                    }
                }
            ],
            "travellerMapping": [
                {
                    "travellerIds": [
                        "A",
                        "C"
                    ],
                    "maxNumberOfTravellers": 2,
                    "minNumberOfTravellers": 2,
                    "userType": "ADULT"
                }
            ],
            "available": 5
        }, ...
    ],
    "recommendations": [
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2839-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "durationType": "SINGLE_TRIP",
            "fareClass": "STANDARD_CLASS",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "f3d5bbe9-f9b2-47c4-a7b2-e172f4f11bdf",
                    "numberToBuy": 2,
                    "withUpgradeProducts": [],
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "B"
                        ],
                        [
                            "A"
                        ]
                    ]
                },
                {
                    "id": "68b49f9d-7334-4279-ab61-437d08eca977",
                    "numberToBuy": 2,
                    "withUpgradeProducts": [],
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "D"
                        ],
                        [
                            "C"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2839-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "durationType": "SINGLE_TRIP",
            "fareClass": "PREMIUM_CLASS",
            "facilitySet": "SLEEPER",
            "offersToBuy": [
                {
                    "id": "acef5e44-5a02-4136-ab6d-5aaa7d0233e2",
                    "numberToBuy": 1,
                    "withUpgradeProducts": [],
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "B",
                            "D"
                        ]
                    ]
                },
                {
                    "id": "9e71c4ee-2173-47d9-882d-d1d6d38f736f",
                    "numberToBuy": 1,
                    "withUpgradeProducts": [],
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "A",
                            "C"
                        ]
                    ]
                }
            ]
        }
    ],
    "unavailableProducts": []
}`}
    </pre>
    </ExpandablePanel>
    <h1 {...{
      "id": "search-by-stop-places"
    }}><a parentName="h1" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/stop-places/postOffersV2SearchStopPlaces"
      }}>{`Search by stop-places`}</a></h1>
    <p>{`Use the stop-places endpoint if you want to request offers that are not associated with any specific departure.
The offers returned are period tickets or single tickets that are valid between stop places and for a given duration.
Some combinations of stop places may result in offers that cover parts of the distance.
As an example, let's say the "from" stop place is A, and the "to" stop place is C.
There may not be a ticket that is valid from A to C, but tickets that are valid from A to B, and B to C.
In that case you will receive offers that are valid for A to B and B to C.
If you only want offers that cover the whole distance, set "offersForWholeTripOnly" to true.`}</p>
    <h2 {...{
      "id": "examplerequest-to-searchstop-places"
    }}>{`ExampleRequest to search/stop-places`}</h2>
    <ExpandablePanel title="ExampleRequest to search/stop-places" mdxType="ExpandablePanel">
    <pre>{`
        "travellers": [
            {
                "userType": "ADULT"
            }
        ],
        "travelDate": "2022-02-24T00:00:00+00",
        "from": "NSR:StopPlace:337",
        "to": "NSR:StopPlace:421",
        "offersForWholeTripOnly": false
    `}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "exampleresponse-from-searchstop-places"
    }}>{`ExampleResponse from search/stop-places`}</h2>
    <ExpandablePanel title="ExampleResponse from search/stop-places" mdxType="ExpandablePanel">
    <pre>{`
        {
            "offers": [
                {
                    "id": "c76b31fa-2cd8-4415-aa6c-ff3c1e761a32",
                    "name": "365-dagersbillett",
                    "description": "",
                    "price": {
                        "amount": "100020.00",
                        "currency": "NOK"
                    },
                    "geographicalValidity": {
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:421"
                        }
                    },
                    "preassignedProducts": [
                        {
                            "id": "GOA:PreassignedFareProduct:365days",
                            "version": "GOA:Version:Pfp365days-1",
                            "name": "365-dagersbillett",
                            "description": "- Billetten gjelder for reiser i begge retninger på valgt strekning og for et ubegrenset antall reiser\\n\\nLes mer om betingelser og reisevilkår:\\n[Transportvilkår for Go-Ahead](https://go-aheadnordic.no/om-go-ahead-nordic/transportvilkar)\\n\\n[Refusjonsbetingelser for Go-Ahead](https://go-aheadnordic.no/reiseinformasjon/endring-refusjon-og-gebyrer)",
                            "properties": {
                                "isExchangeable": true,
                                "isRefundable": false,
                                "userProfileId": "GOA:UserProfile:Adult",
                                "userProfileVersion": "GOA:Version:V1",
                                "organisation": {
                                    "id": "GOA:Authority:GOA",
                                    "name": "Go-Ahead Nordic"
                                },
                                "duration": "PT8760H",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ]
                            },
                            "geographicalValidity": {
                                "pointToPointValidity": {
                                    "fromPlace": "NSR:StopPlace:337",
                                    "toPlace": "NSR:StopPlace:421"
                                }
                            }
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "871c6e3b-7a00-4c5d-8cdf-15e882928593"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                },
                {
                    "id": "5ef7c6a2-17e3-45cc-bcba-ebcbecb097df",
                    "name": "30-dagersbillett",
                    "description": "",
                    "price": {
                        "amount": "10002.00",
                        "currency": "NOK"
                    },
                    "geographicalValidity": {
                        "pointToPointValidity": {
                            "fromPlace": "NSR:StopPlace:337",
                            "toPlace": "NSR:StopPlace:421"
                        }
                    },
                    "preassignedProducts": [
                        {
                            "id": "GOA:PreassignedFareProduct:30days",
                            "version": "GOA:Version:Pfp30days-1",
                            "name": "30-dagersbillett",
                            "description": "- Billetten gjelder for reiser i begge retninger på valgt strekning og for et ubegrenset antall reiser\\n\\nLes mer om betingelser og reisevilkår:\\n[Transportvilkår for Go-Ahead](https://go-aheadnordic.no/om-go-ahead-nordic/transportvilkar)\\n\\n[Refusjonsbetingelser for Go-Ahead](https://go-aheadnordic.no/reiseinformasjon/endring-refusjon-og-gebyrer)",
                            "properties": {
                                "isExchangeable": true,
                                "isRefundable": false,
                                "userProfileId": "GOA:UserProfile:Adult",
                                "userProfileVersion": "GOA:Version:V1",
                                "organisation": {
                                    "id": "GOA:Authority:GOA",
                                    "name": "Go-Ahead Nordic"
                                },
                                "duration": "PT720H",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ]
                            },
                            "geographicalValidity": {
                                "pointToPointValidity": {
                                    "fromPlace": "NSR:StopPlace:337",
                                    "toPlace": "NSR:StopPlace:421"
                                }
                            }
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "871c6e3b-7a00-4c5d-8cdf-15e882928593"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                }, ...
            ]
        }
        `}</pre>
    </ExpandablePanel>
    <h1 {...{
      "id": "search-by-authority"
    }}><a parentName="h1" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/authority/getOffersV2AuthorityId"
      }}>{`Search by authority`}</a></h1>
    <p>{`Use the search/authority endpoint if you want to get offers for products with a static geographical validity, using only a traveller and authority as input.
Only constraints that will have an affect are Purchase Window, Authority, Distribution Channel and User Profile, everything else will be ignored.`}</p>
    <h2 {...{
      "id": "examplerequests-to-searchauthority"
    }}>{`ExampleRequests to search/authority`}</h2>
    <ExpandablePanel title="ExampleRequest to search/authority" mdxType="ExpandablePanel">
    <pre>{`
    {
        "travelDate": "2022-10-15T03:00:00.000Z",
        "travellers": [
            {
                "userType": "ADULT"
            }
        ],
        "authorityId": "ATB:Authority:2"
    }
    `}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "exampleresponses-from-searchauthority"
    }}>{`ExampleResponses from search/authority`}</h2>
    <ExpandablePanel title="ExampleResponse from search/authority" mdxType="ExpandablePanel">
    <pre>{`
        {
            "offers": [
                {
                    "id": "4b2fe5c8-eb81-4015-8a3a-0ed32861e4d2",
                    "name": "Natt 2",
                    "description": "Natt 2",
                    "price": {
                        "amount": "150.00",
                        "currency": "NOK"
                    },
                    "availableFulfillmentMethods": [
                        {
                            "id": "ENT:FulfilmentMethod:IdBasert",
                            "version": "ENT:Version:FM-IdBasert-1"
                        }
                    ],
                    "geographicalValidity": {},
                    "preassignedProducts": [
                        {
                            "id": "ATB:PreassignedFareProduct:877baeff",
                            "version": "ATB:Version:c463bb9c-7e63-4c84-a07b-837dc264694a",
                            "name": "Natt 2",
                            "description": "Natt 2",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileSummary": {
                                    "id": "ATB:UserProfile:8ee842e3",
                                    "version": "ATB:Version:230ef468-95a1-461a-b109-104ac046cb0d",
                                    "name": "Voksen",
                                    "userType": "ADULT"
                                },
                                "organisation": {
                                    "id": "ATB:Authority:2",
                                    "name": "AtB"
                                },
                                "duration": "PT1H30M",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ],
                                "durationType": "SINGLE_TRIP"
                            },
                            "geographicalValidity": {}
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "3ece9584-4e03-4536-90ce-0037893b5e88"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                },
                {
                    "id": "db2d4e67-bd0c-40d6-afc0-4e016566809b",
                    "name": "Natt 3",
                    "description": "Natt 3",
                    "price": {
                        "amount": "200.00",
                        "currency": "NOK"
                    },
                    "availableFulfillmentMethods": [
                        {
                            "id": "ENT:FulfilmentMethod:IdBasert",
                            "version": "ENT:Version:FM-IdBasert-1"
                        }
                    ],
                    "geographicalValidity": {},
                    "preassignedProducts": [
                        {
                            "id": "ATB:PreassignedFareProduct:e317a807",
                            "version": "ATB:Version:ffdc03c6-2003-429f-a0bc-b8ddaa6b8f9d",
                            "name": "Natt 3",
                            "description": "Natt 3",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileSummary": {
                                    "id": "ATB:UserProfile:8ee842e3",
                                    "version": "ATB:Version:230ef468-95a1-461a-b109-104ac046cb0d",
                                    "name": "Voksen",
                                    "userType": "ADULT"
                                },
                                "organisation": {
                                    "id": "ATB:Authority:2",
                                    "name": "AtB"
                                },
                                "duration": "PT1H30M",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ],
                                "durationType": "SINGLE_TRIP"
                            },
                            "geographicalValidity": {}
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "3ece9584-4e03-4536-90ce-0037893b5e88"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                },
                {
                    "id": "cbe79d7e-99b5-4693-978e-e37ac21a2f0f",
                    "name": "Natt 1",
                    "description": "Natt 1",
                    "price": {
                        "amount": "100.00",
                        "currency": "NOK"
                    },
                    "availableFulfillmentMethods": [
                        {
                            "id": "ENT:FulfilmentMethod:IdBasert",
                            "version": "ENT:Version:FM-IdBasert-1"
                        }
                    ],
                    "geographicalValidity": {},
                    "preassignedProducts": [
                        {
                            "id": "ATB:PreassignedFareProduct:8f351521",
                            "version": "ATB:Version:fecb1f30-5b6a-473c-9987-d8ff1b649560",
                            "name": "Natt 1",
                            "description": "Natt 1",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileSummary": {
                                    "id": "ATB:UserProfile:8ee842e3",
                                    "version": "ATB:Version:230ef468-95a1-461a-b109-104ac046cb0d",
                                    "name": "Voksen",
                                    "userType": "ADULT"
                                },
                                "organisation": {
                                    "id": "ATB:Authority:2",
                                    "name": "AtB"
                                },
                                "duration": "PT1H30M",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ],
                                "durationType": "SINGLE_TRIP"
                            },
                            "geographicalValidity": {}
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "3ece9584-4e03-4536-90ce-0037893b5e88"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                }
            ],
            "explainInfo": {
                "explainNodes": [
                    {
                        "id": "ATB:SalesPackage:55246449",
                        "version": "ATB:Version:35e3b7f3-5158-45ee-9488-9dbc49ff95fe",
                        "reason": "noValidVersion"
                    }
                ]
            }
        }
        `}</pre>
    </ExpandablePanel>
    <h1 {...{
      "id": "search-by-zones"
    }}><a parentName="h1" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/api-docs/offers#/zones/postOffersV2SearchZones"
      }}>{`Search by zones`}</a></h1>
    <p>{`Use the search/zones endpoint if you want to get offers for products that have validity based on zones specified.
As with the search/stop-places endpoint, the search/zones endpoint responds with products that are not limited to a specific departure.
From search/zones you will receive single tickets and period tickets that are valid in the zones specified and for the duration specified in the period ticket.`}</p>
    <h2 {...{
      "id": "examplerequests-to-searchzones"
    }}>{`ExampleRequests to search/zones`}</h2>
    <ExpandablePanel title="ExampleRequest to search/zones without existingTicket specified" mdxType="ExpandablePanel">
    <pre>{`
    {
    	"from": "RUT:TariffZone:1",
    	"to": "RUT:TariffZone:2Ø",
    	"travelDate": "2022-03-20T11:39:56Z",
    	"travellers": [
    	    {
    	        "userType": "ADULT"
    	    }
    	]
    }
    `}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="ExampleRequest to search/zones with existingTicket specified" mdxType="ExpandablePanel">
    <pre>{`
    {
        "from": "RUT:TariffZone:1",
        "to": "RUT:TariffZone:2Ø",
        "travelDate": "2022-03-20T11:39:56Z",
        "travellers": [
            {
                "userType": "ADULT"
            }
        ],
        "existingTicket": {
            "id": "RUT:PreassignedFareProduct:Ruter24Hours",
            "zones": [
                "RUT:TariffZone:1"
            ]
        }
    }
    `}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "exampleresponses-from-searchzones"
    }}>{`ExampleResponses from search/zones`}</h2>
    <ExpandablePanel title="ExampleResponse from search/zones without existingTicket specified" mdxType="ExpandablePanel">
    <pre>{`
        {
        "offers": [
            {
                "id": "c689eaea-7fca-40ff-b5f0-2d177e0ee4e7",
                "name": "24-timersbillett",
                "description": "",
                "price": {
                    "amount": "192.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:TariffZone:1",
                            "RUT:TariffZone:2Ø"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:Ruter24Hours",
                        "version": "RUT:Version:V1",
                        "name": "24-timersbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": false,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT24H",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:TariffZone:1",
                                    "RUT:TariffZone:2Ø"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            },
            {
                "id": "dabdd6a1-385a-4ed6-9925-3ee3c06f0a1f",
                "name": "30-dagersbillett",
                "description": "",
                "price": {
                    "amount": "1443.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:TariffZone:1",
                            "RUT:TariffZone:2Ø"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:Ruter30Days",
                        "version": "RUT:Version:V1",
                        "name": "30-dagersbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": true,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT720H",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:TariffZone:1",
                                    "RUT:TariffZone:2Ø"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            },
            {
                "id": "df2065db-c27b-4834-bca7-a9c42de858df",
                "name": "Enkeltbillett",
                "description": "",
                "price": {
                    "amount": "64.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:TariffZone:1",
                            "RUT:TariffZone:2Ø"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:RuterSingleTicket",
                        "version": "RUT:Version:V1",
                        "name": "Enkeltbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": false,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT1H30M",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:TariffZone:1",
                                    "RUT:TariffZone:2Ø"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            },
            {
                "id": "da7c59d5-97d3-49b6-bd8d-b619147264bc",
                "name": "365-dagersbillett",
                "description": "",
                "price": {
                    "amount": "14430.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:TariffZone:1",
                            "RUT:TariffZone:2Ø"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:Ruter365Days",
                        "version": "RUT:Version:V1",
                        "name": "365-dagersbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": true,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT8760H",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:TariffZone:1",
                                    "RUT:TariffZone:2Ø"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            },
            {
                "id": "ac943960-7166-4e40-a1d3-f5e133f1a482",
                "name": "7-dagersbillett",
                "description": "",
                "price": {
                    "amount": "564.00",
                    "currency": "NOK"
                },
                "geographicalValidity": {
                    "zonalValidity": {
                        "zones": [
                            "RUT:TariffZone:1",
                            "RUT:TariffZone:2Ø"
                        ]
                    }
                },
                "preassignedProducts": [
                    {
                        "id": "RUT:PreassignedFareProduct:Ruter7Days",
                        "version": "RUT:Version:V1",
                        "name": "7-dagersbillett",
                        "description": "",
                        "properties": {
                            "isExchangeable": false,
                            "isRefundable": true,
                            "userProfileId": "RUT:UserProfile:Adult",
                            "userProfileVersion": "RUT:Version:V1",
                            "organisation": {
                                "id": "RUT:Authority:RUT",
                                "name": "Ruter"
                            },
                            "duration": "PT168H",
                            "fareClasses": [
                                "STANDARD_CLASS"
                            ]
                        },
                        "geographicalValidity": {
                            "zonalValidity": {
                                "zones": [
                                    "RUT:TariffZone:1",
                                    "RUT:TariffZone:2Ø"
                                ]
                            }
                        }
                    }
                ],
                "travellerMapping": [
                    {
                        "travellerIds": [
                            "33a40b8b-8588-483c-a24d-5a1a337f5ead"
                        ],
                        "maxNumberOfTravellers": 1,
                        "minNumberOfTravellers": 1,
                        "userType": "ADULT"
                    }
                ]
            }
        ]
    }
        `}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="ExampleResponse from search/zones with existingTicket specified" mdxType="ExpandablePanel">
    <pre>{`
        {
            "offers": [
                {
                    "id": "e648a768-ddc9-494f-871d-2b031a19c0fe",
                    "name": "Enkeltbillett",
                    "description": "",
                    "price": {
                        "amount": "39.00",
                        "currency": "NOK"
                    },
                    "geographicalValidity": {
                        "zonalValidity": {
                            "zones": [
                                "RUT:TariffZone:2Ø"
                            ]
                        }
                    },
                    "preassignedProducts": [
                        {
                            "id": "RUT:PreassignedFareProduct:RuterSingleTicket",
                            "version": "RUT:Version:V1",
                            "name": "Enkeltbillett",
                            "description": "",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileId": "RUT:UserProfile:Adult",
                                "userProfileVersion": "RUT:Version:V1",
                                "organisation": {
                                    "id": "RUT:Authority:RUT",
                                    "name": "Ruter"
                                },
                                "duration": "PT1H",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ]
                            },
                            "geographicalValidity": {
                                "zonalValidity": {
                                    "zones": [
                                        "RUT:TariffZone:2Ø"
                                    ]
                                }
                            }
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "12dccdb3-b8cf-4333-b569-243349fa6913"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                },
                {
                    "id": "408c8126-3418-4090-8a1c-417478d66b1c",
                    "name": "",
                    "description": "",
                    "price": {
                        "amount": "25.00",
                        "currency": "NOK"
                    },
                    "geographicalValidity": {
                        "zonalValidity": {
                            "zones": [
                                "RUT:TariffZone:2Ø"
                            ]
                        }
                    },
                    "preassignedProducts": [
                        {
                            "id": "RUT:PreassignedFareProduct:SupplementaryTicket",
                            "version": "RUT:Version:V1",
                            "name": "Billett for ekstra soner",
                            "description": "",
                            "properties": {
                                "isExchangeable": false,
                                "isRefundable": false,
                                "userProfileId": "RUT:UserProfile:Adult",
                                "userProfileVersion": "RUT:Version:V1",
                                "organisation": {
                                    "id": "RUT:Authority:RUT",
                                    "name": "Ruter"
                                },
                                "duration": "PT1H30M",
                                "fareClasses": [
                                    "STANDARD_CLASS"
                                ]
                            },
                            "geographicalValidity": {
                                "zonalValidity": {
                                    "zones": [
                                        "RUT:TariffZone:2Ø"
                                    ]
                                }
                            }
                        }
                    ],
                    "travellerMapping": [
                        {
                            "travellerIds": [
                                "12dccdb3-b8cf-4333-b569-243349fa6913"
                            ],
                            "maxNumberOfTravellers": 1,
                            "minNumberOfTravellers": 1,
                            "userType": "ADULT"
                        }
                    ]
                }
            ]
        }
        `}</pre>
    </ExpandablePanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      